<template>
  <!-- ******************* Start CONTACTANOS ************************** -->
    <div id="componentcontact">
      <mdb-container>
        <mdb-card-title v-show="ActContact" style="color:green" class="text-left" ><b>Te interesa la propiedad contactanos</b></mdb-card-title>
        <mdb-card-title v-show="!ActContact" class="Texto3 text-left">Contactanos</mdb-card-title>
        <mdb-card>
            <mdb-card-body class="text-left">
                    <form class="needs-validation" novalidate @submit.prevent="submitForm">
                      <mdb-row>
                        <mdb-col md="8">
                          <mdb-row>
                            <mdb-col>
                              <mdb-input label="Nombre" v-model="fields.name" required />
                            </mdb-col>
                          </mdb-row>

                          <mdb-row>
                            <mdb-col>
                              <mdb-input type="number" label="Telefono" v-model="fields.tel" required />
                              <h6 v-show="Acttel" style="color:red;">Es necesario agregar un telefono para ofrecer un mejor servicio</h6>
                            </mdb-col>
                          </mdb-row>

                          <mdb-row>
                            <mdb-col>
                              <mdb-input type="email" label="Tu email" v-model="fields.email" required />
                              <h6 v-show="Actemail"  style="color:red;">Es necesario agregar un email para ofrecer un mejor servicio</h6>
                            </mdb-col>
                          </mdb-row>

                          <mdb-row>
                            <mdb-col>
                              <mdb-input label="Asunto" v-model="fields.subject" required />
                            </mdb-col>
                          </mdb-row>
                          <mdb-row>
                            <mdb-col>
                              <mdb-input type="textarea" label="Escribe tu mensaje" v-model="fields.message" required />
                            </mdb-col>
                          </mdb-row>
                        </mdb-col>
                        <mdb-col md="4">
                          <mdb-row>
                            <mdb-col class="mt-4">
                              <mdb-icon icon="map-marker-alt" size="2x" />
                              <p> {{ContactData.direccion}}</p>
                            </mdb-col>
                          </mdb-row>
                          <mdb-row>
                            <mdb-col class="mt-4">
                              <mdb-icon icon="phone" size="2x" />
                              <p>+52 {{ContactData.tel}} </p>
                            </mdb-col>
                          </mdb-row>
                          <mdb-row>
                            <mdb-col class="mt-4">
                              <mdb-icon icon="envelope" size="2x" />
                              <p>{{ContactData.email}}</p>
                            </mdb-col>
                          </mdb-row>
                        </mdb-col>
                      </mdb-row>
                      <mdb-btn size="lg" @click="SendMessage"  class="float-left"  style="background-color: #00796b " > <strong> Enviar </strong> </mdb-btn>
                    </form>
            </mdb-card-body>
          </mdb-card>
      </mdb-container>
      <br>
      <mdb-row>
        <mdb-col style="background-color: #00796b"   sm="2">
          <br>
        </mdb-col>
      </mdb-row>
        <br>
    </div>
  <!-- ******************* End CONTACTANOS ************************** -->
</template>



<script>
import axios from 'axios';
import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import {mdbInput,mdbContainer} from "mdbvue";
import { firebase, storage, db } from "@/firebase";
import { mapActions,mapState } from 'vuex'
export default {
  name: 'componentcontact',
  props:['id_general'],
  components: {
    mdbInput,
    mdbContainer,
    mdbRow,
		mdbCol,
		mdbCard,
		mdbCardImage,
		mdbCardHeader,
		mdbCardBody,
		mdbCardTitle,
		mdbCardText,
		mdbCardFooter,
		mdbCardUp,
		mdbCardAvatar,
		mdbCardGroup,
		mdbBtn,
		mdbView,
		mdbMask,
		mdbIcon,
  },
    data() {
      return {
        Acttel:false,
        Actemail:false,
        fields: {
          name: "",
          email: "",
          subject: "",
          message: "",
          tel:"",
        },
      };
    },
    computed:{
      ...mapState(['ContactData','ActContact'])
    },
    methods: {
      submitForm(event) {
        event.target.classList.add("was-validated");
        // submit form
      },
      async SendEmail(){
          const headers = { "Content-Type": "application/json" };
          let datos={
              name:this.fields.name,
              emailto:"diegosalgadogiles96@gmail.com",
              emailfrom:this.fields.email,
              cel: this.fields.subject,
              message:this.fields.message
            }
          await axios.post('http://31.220.53.122:3000/SendNotification/WebPages',datos,{headers}).then(response => {
            console.log("recibido")
            })
          .catch(error => console.log(error))

        },
      clear(){
        this.fields.name=""
        this.fields.email=""
        this.fields.subject=""
        this.fields.message=""
        this.fields.tel=""

      },
      SendMessage(){
        if(this.fields.tel != ""){
          this.Acttel=false
          if(this.fields.email!=""){
            var self = this
            this.Actemail=false
            if(this.id_general != null){
              var Id_c = self.id_general.id_general
              var Id_general_c = self.id_general.id
            } else {
              var Id_c = null
              var Id_general_c = null
            }

            db.collection("Messages").add({
                  time: Date.now(),
                  name: this.fields.name,
                  email: this.fields.email,
                  subject: this.fields.subject,
                  message: this.fields.message,
                  tel: this.fields.tel,
                  Id:Id_c,
                  Id_prop:Id_general_c,
              })
              .then(function(docRef) {

                db.collection("Messages").doc(docRef.id).update({
                  id_general: docRef.id

                }).then(function() {
                    console.log("Document successfully updated!");
                    self.clear()
                    // self.SendEmail()
                    alert('Mensaje Enviado')
                })
                console.log("Document written with ID: ", docRef.id);
              })
              .catch(function(error) {
                  console.error("Error adding document: ", error);
              });
          }else{
            this.Actemail=true

          }
        }else{
            if(this.fields.email!=""){
              this.Actemail=false
            }else{
              this.Actemail=true
            }
          this.Acttel=true

        }

    },
      ...mapActions(['getContactData'])

    },
    created () {
      console.log('2 - created frome ComponentContact.vue')
      this.getContactData()
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .Texto4{
    font-family: "Segoe UI";
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 25px;
  }
  .container {
    text-align: center;
  }
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
</style>
