<template>
  <!-- ******************* Start  Home ************************** -->
    <div id="inicio">
      <mdb-carousel :interval="8000" slide :items="imgCarrucel" controlls></mdb-carousel>
      <FindProperties></FindProperties>
      <br>
      <mdb-row>
        <mdb-col style="background-color: #00796b"   sm="2">
          <br>
        </mdb-col>
      </mdb-row>
      <br>
    </div>
  <!-- *************************End  Home ****************************************************** -->

</template>




<script>
import FindProperties from './FindProperties.vue'
import { mapActions,mapState } from 'vuex'
import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import { mdbCarousel,mdbContainer } from "mdbvue";
import {mdbInput} from "mdbvue";
export default {
    name: 'inicio',
    components: {
      FindProperties,
      mdbInput,
      mdbCarousel,
      mdbContainer,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
			mdbBtn,
			mdbView,
			mdbMask,
			mdbIcon,
    },
    data() {
      return {

      };
    },
    methods: {
      submitForm(event) {
        event.target.classList.add("was-validated");
      },
      ...mapActions(['getImgCarrucel'])
    },
    computed:{
      ...mapState(['imgCarrucel'])
    },
    mounted(){
      this.getImgCarrucel();
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  h1 {
    font-family: "sans-serif";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .Texto1{
    font-family: "Georgia";
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }

  .container {
    text-align: center;
  }
</style>
