<template>
  <!-- ******************* Start Informacion ************************** -->
    <div id=footerinfo>
      <div>
        <!-- Footer -->
        <footer class="page-footer font-small success-color-dark pt-4">
          <!-- Footer Links -->
          <div class="container text-center text-md-left">
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-md-4 mx-auto">
                <!-- Content -->
                <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Siguenos en:</h5>

                <a class="btn-floating btn-lg light-blue darken-4" type="button" role="button" v-bind:href='ContactData.facebook'><i class="fab fa-facebook-f"></i></a>
                <!--Twitter-->
                <a class="btn-floating btn-lg light-blue lighten-2" type="button" role="button" v-bind:href='ContactData.twiter'><i class="fab fa-twitter"></i></a>

                <!--WhatsApp-->
                <a class="btn-floating btn-lg green accent-3" type="button" role="button" v-bind:href="ContactData.whatsapp"><i class="fab fa-whatsapp"></i></a>

              </div>


              <!-- Grid column -->
              <hr class="clearfix w-100 d-md-none">
              <!-- Grid column -->
              <div class="col-md-4 mx-auto">
                <!-- Links -->
                <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Contacto</h5>
                <ul class="list-unstyled">
                  <li>
                    Telefono: +52 {{ContactData.tel}}
                  </li>
                  <li>
                    Direccion: {{ContactData.direccion}}
                  </li>
                  <li>
                    Email: {{ContactData.email}}
                  </li>
                </ul>

              </div>
              <!-- Grid column -->
              <hr class="clearfix w-100 d-md-none">
                <!-- Grid column -->
                <div class="col-md-4 mx-auto">
                  <!-- Links -->
                  <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Información</h5>
                  <ul class="list-unstyled">
                    <li>
                      Contactenos  Via Messenger
                    </li>
                    <li>
                      <a href="/privacidaddatos" >Política de Privacidad de datos</a>
                    </li>
                    <li>
                      <a href="/terminoscondiciones" >Aviso legal</a>
                    </li>
                    <li>
                      <a href="/cookies" >Política de cookies</a>
                    </li>
                    <li>
                     Necesitas rentar o vender tu propidad
                    </li>
                  </ul>
                </div>
                <!-- Grid column -->
              </div>
              <!-- Grid row -->
            </div>
            <!-- Copyright -->
            <div class="footer-copyright text-center py-3">
                © 2020 NOVO Inmobiliaria
                <div>Iconos diseñados por <a href="https://www.flaticon.es/autores/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</a></div>
                <br>
            </div>
            <!-- Copyright -->
          </footer>
          <!-- Footer -->
        </div>
      </div>
  <!-- ******************* End Informacion ************************** -->
</template>



<script>
  import { mdbFooter} from 'mdbvue';
  import { mapActions,mapState } from 'vuex'
  export default {
    name: 'inicio',
    components: {
      mdbFooter,
    },
    data() {
      return {
      }
    },
    computed:{
      ...mapState(['ContactData'])
    },
    methods: {
      submitForm(event) {
        event.target.classList.add("was-validated");
      },
      ...mapActions(['getContactData'])
    },
    // beforeCreate () {
    //   console.log('1 - beforeCreate from FooterInfo.vue')
    // },
    created () {
      console.log('2 - created frome FooterInfo.vue')
      this.getContactData()
    },
    // beforeMount () {
    //   console.log('3 - beforeMount frome FooterInfo.vue')
    // },
    // mounted () {
    //   console.log('4 - mounted frome FooterInfo.vue')
    //
    // },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate frome Home.vue')
    // },
    // Updated () {
    //   console.log('6 - Update')
    // },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  .container {
    text-align: center;
  }

</style>
