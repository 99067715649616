import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);



import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBa3WxzCQrB9V2Et36F96psFzl0U-Q8yTU",
  },
});

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// import VueHtml2Canvas from 'vue-html2canvas';
// Vue.use(VueHtml2Canvas);
import VueHtml2Canvas from 'vue-html2canvas';

Vue.use(VueHtml2Canvas);
import VueFbCustomerChat from 'vue-fb-customer-chat'

Vue.use(VueFbCustomerChat, {
  page_id: "1861515574146493", //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'es_ES', // default 'en_US'
})

Vue.config.productionTip = false

import {auth} from './firebase'

auth.onAuthStateChanged(user => {
  if(user){
    console.log(user)
    const detect_user={
      email:user.email,
      uid:user.uid,
    }
    store.dispatch('detect_user',detect_user)
  }else{
    console.log(user)
    store.dispatch('detect_user',user)
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
