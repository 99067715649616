<template>
  <!-- ******************* Start  Recientes ************************** -->
    <div id="actualproperties">
      <!-- <p class="text-left Texto3"> Recientes</p> -->
      <mdb-container>
        <mdb-card-title class="text-left Texto3">Recientes</mdb-card-title>
          <mdb-container>
            <mdb-row>
              <mdb-col  sm="12" md="6" lg="4" xl="4" v-for="(item, index) in dataProps_frontend_actual">
                <mdb-container v-show="item.DataProp.control_active == true">
            		<mdb-row>
            			<mdb-card-group>
            				<mdb-card style="margin-top:50px">
            					<mdb-view hover>
            						<a href="#!">
                          <img @click="GoToPropertie(item.DataProp.id_general)" class="img1" :src="item.DataProp.src[0].src" height="200"	alt="Card image cap">
            						</a>
            					</mdb-view>
            					<mdb-card-body>
                        <mdb-card-title class="Texto4"  style=" font-weight: bold; color:#689f38  ;">{{item.DataProp.name}} </mdb-card-title>
                        <p><h1 style="font-weight: bold; color:#000000"  class="text-left">$ {{item.DataProp.precio}}</h1></strong> </p>
                        <mdb-container>
                          <mdb-row>
                            <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                                <span id="basic-addon">
                                  <mdb-icon icon="bath" />
                                  <p>{{ item.DataProp.Baño }} Baños </p>
                                </span>
                            </mdb-col>
                            <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                                <span id="basic-addon">
                                  <mdb-icon icon="bed" />
                                  <p>{{ item.DataProp.Habitacion }} Recamaras</p>
                                </span>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                          <mdb-col col="5" sm="5" md="5" lg="5" xl="6" >
                              <span id="basic-addon">
                                <mdb-icon icon="car" />
                                <p>{{ item.DataProp.Garaje }} Garage </p>
                              </span>
                          </mdb-col>
                          <mdb-col col="7" sm="7" md="7" lg="7" xl="6">
                              <span id="basic-addon">
                                <img src="@/assets/terreno.png" width="22" height="22">
                                <p>{{ item.DataProp.Terreno }} m2</p>
                              </span>
                          </mdb-col>
                      </mdb-row>
                      </mdb-container>
                  			<mdb-card-text></mdb-card-text>
                  		 <mdb-btn @click="GoToPropertie(item.DataProp.id_general)" color="green">Mas Información</mdb-btn>
            					</mdb-card-body>
            				</mdb-card>
            			</mdb-card-group>
            		</mdb-row>
            	</mdb-container>

              </mdb-col sm="4">
              <br>
            </mdb-row>
          </mdb-container>
    				<!-- </mdb-card-body>
    			</mdb-card> -->
      <br>
      <div>
         <!-- @click="siguiente" :disabled="ActiveBtnNext" -->
        <mdb-btn size="lg" @click="SMasProps" class="float-left"  style="background-color: #00796b " > <strong> Ver mas Propiedades </strong> </mdb-btn>
      </div>
      <br>
      <br>
      </mdb-container>
      <br>
      <mdb-row>
        <mdb-col style="background-color: #00796b"   sm="2">
          <br>
        </mdb-col>
      </mdb-row>
      <br>
    </div>
  <!-- ******************* END Recientes ************************** -->
</template>





<script>
import router from '../router'
import { firebase, storage, db } from "@/firebase";
import { mapActions,mapState } from 'vuex'
import { mdbFooter} from 'mdbvue';
import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import { mdbJumbotron} from 'mdbvue';
import { mdbCarousel } from "mdbvue";
import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem, mdbContainer, mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav, mdbNavItem } from 'mdbvue';
import {mdbInput} from "mdbvue";
export default {
  name: 'actualproperties',
    components: {
      mdbInput,
      mdbFooter,
      mdbCarousel,
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
      mdbContainer,
      mdbDropdown,
      mdbDropdownToggle,
      mdbDropdownMenu,
      mdbDropdownItem,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
			mdbBtn,
			mdbView,
			mdbMask,
			mdbIcon,
      mdbJumbotron,
    },
    data() {
      return {
        DataPropsFrontend:[],
        ActiveBtnNext:false,
      };
    },
    methods: {
      ...mapActions(['ChangeValueBoolean']),
      GoToPropertie(id_general){
        var self = this
        console.log(id_general)
        self.$router.push({ name: 'Propiedad', params: { id: id_general } })
      },
      SMasProps(){
        var self= this
        if(self.$route.name == 'Home'){
          self.$router.push({ path: '/properties' })
        }
      },
      ReadProps(){
        var self = this
        db.collection("Propiedades")
        .limit(9)
        .orderBy('DataProp.fecha')
        .get()
        .then(function(querySnapshot) {
          //var last = querySnapshot.docs[querySnapshot.docs.length -1];
          // self.ChangeValueBoolean({var:'dataProps_last_actual',val:last})
          self.DataPropsFrontend=[]
          querySnapshot.forEach(function(doc) {
            self.DataPropsFrontend.push(doc.data())
            console.log(doc.id, " => ", doc.data());
          });
          self.ChangeValueBoolean({var:'dataProps_frontend_actual',val:self.DataPropsFrontend})
        });

      },

    },
    computed:{
      ...mapState(['dataProps_frontend_actual','dataProps_last_actual'])
    },
    created () {
      console.log('2 - created frome Home.vue')
      this.ReadProps()
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  h1 {
    font-family: "sans-serif";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .Texto1{
    font-family: "Georgia";
    /* font-weight: bold;
    text-transform: uppercase; */
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .Texto4{
    font-family: "Segoe UI";
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 25px;
  }
  .container {
    text-align: center;
  }
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.appWhatsapp{
  position:fixed;
  right: 26px;
  bottom:100px;
  width: 60px;
  z-index:100;
}
.appWhatsapp img{
  width:100%;
  height:auto;
}

</style>
