<template>

  <div id="home">
    <footer>
      <cookie-law theme="dark-lime">
        <div slot="message">
          Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios.
          Si acepta o continúa navegando, consideramos que acepta su uso. Puede cambiar la configuración u obtener más información
          <router-link to="/cookies">aquí.</router-link>
        </div>
      </cookie-law>
    </footer>
    <Whatsapp :id_general="null"></Whatsapp>
    <NavBarGeneral> </NavBarGeneral>
    <Inicio> </Inicio>
    <ActualProperties>    </ActualProperties>
    <ComponentContact :id_general=null> </ComponentContact>
    <AboutUs></AboutUs>
    <FooterInfo></FooterInfo>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import FooterInfo from "@/components/FooterInfo.vue"
import ComponentContact from "@/components/ComponentContact.vue"
import AboutUs from "@/components/AboutUs.vue"
import Inicio from "@/components/Inicio.vue"
import NavBarGeneral from "@/components/NavBarGeneral.vue"
import ActualProperties from "@/components/ActualProperties.vue"
import Whatsapp from "@/components/Whatsapp.vue"
import { mapActions,mapState } from 'vuex';

  export default {
    name: 'Home',
    components: {
      CookieLaw,
      Whatsapp,
      FooterInfo,
      AboutUs,
      ActualProperties,
      Inicio,
      NavBarGeneral,
      ComponentContact,
    },
    data() {
      return {

      };
    },
    computed:{

    },
    methods: {
      ...mapActions(['readLocalidades','readLogo','AddVisit']),
     //  setCookie(){
     //     // it sets the cookie called `username`
     //   this.$cookies.set("username","gowtham");
     // }
    },
    // Inicio Ciclo de vida
    // beforeCreate () {
    //   console.log('1 - beforeCreate frome Home.vue')
    // },
    //
    // beforeMount () {
    //   console.log('3 - beforeMount frome Home.vue')
    // },
    mounted () {
      // console.log('4 - mounted frome Home.vue')

      this.readLocalidades()
      this.readLogo()
      this.AddVisit()

    },
    // beforeUpdate () {
    //   console.log('5 - beforeUpdate frome Home.vue')
    // },
    // Updated () {
    //   console.log('6 - Update')
    // },
  }
</script>

<style scoped>
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.appWhatsapp{
  position:fixed;
  right: 26px;
  bottom:100px;
  width: 60px;
  z-index:100;
}
.appWhatsapp img{
  width:100%;
  height:auto;
}

</style>
