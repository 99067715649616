import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {auth, db, firebase, storage} from '../firebase'
import router from '../router'


export default new Vuex.Store({
  state: {
    //Variable  para ccontar mis VISITAS
    CounterVisit:null,
    //Variable del logo
    LogoUrl:null,
    //Variable para leer Localidades
    Localidades:[],
    //Variable para agregar el limite de datos de mensages
    Message_last:0,
    dataMessages:[],
    ActBtnNext_Message:false,

    //Variablr para cambiar el nombre de contactanos
    ActContact:false,
    LogoUrl:null,
    //Serach
    dataProps_frontend_search:[],
    dataSearch:{
      loc:"",
      type:"",
      status:"",
      min: 0,
      max: 0,
      bath: 0,
      room:  0,
      car:  0 ,
      terr:  0,
    },
    dataProps_last_search:0,
    //Varaible for de frontend
    dataProps_frontend_actual:[],
    dataProps_last_actual:null,
    NosotrosData:{
      src:'',
      from:'',
      data_text:''
    },
    ContactData:{
      tel:null,
      email:'',
      facebook:'',
      whatsapp:'',
      twiter:'',
      direccion:'',
      latitud:null,
      longitud:null
    },
    //Variables para pagination
    Longitud:0,
    //variable para pasar los valores  a mostrar para la edicion de las Propiedades
    IndexEditProp:null,
    Ejemplo:[],
    ParamSearch:{
      TxtPropSearch:"",
      PropLocalidad:null,
      PropPropiedad:null,
      PropVentaRenta:null,
      PropPrecioMin:null,
      PropPrecioMax:null,
      PropItemBath:null,
      PropItemBed:null,
      PropItemCar:null,
      PropItemTerreno:null
    },
    //variables Boton mostrar Buscador
    ActSearch:false,
    //variables para el login de usuario
    error:null,
    usuario:null,
    ShowEditProp:false,
    ShowAddProp:false,
    //variable visistar por propiedad
    PropVisits:[],
    PropControlActive:[],
    PropLikes:[],
    //variable par obtener imagenes del carrucle
    imgCarrucel:[],
    // Variable para activar la obtencion de variables
    statusDowProp:false,
    // Variable para almacenar todas las propiedades
    dataProps:[],
    // Variable para almacennar las propiedad con datos Generales
    statusTab:false,
    dataProps_Gen:{
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'Asesor',
          field: 'asesor',
          sort: true
        },
        {
          label: 'Fecha',
          field: 'fecha',
          sort: true
        },
        {
          label: 'Nombre',
          field: 'nombre',
          sort: true,
        },
        {
          label: 'Precio',
          field: 'precio',
          sort: true,
          format: value => '$' + value
        },
        {
          label: 'Mantenimiento',
          field: 'mantenimiento',
          sort: true,
          format: value => '$' + value
        }
      ],
      rows:[],
    },

    dataProps_dir: {
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'Calle',
          field: 'calle',
          sort: true
        },
        {
          label: 'NumeroExt',
          field: 'numero_ext',
          sort: true
        },
        {
          label: 'NumeroInt',
          field: 'numero_int',
          sort: true
        },
        {
          label: 'Colonia',
          field: 'colonia',
          sort: true,
        },
        {
          label: 'CP',
          field: 'cp',
          sort: true
        },
        {
          label: 'Ciudad',
          field: 'ciudad',
          sort: true,

        },
        {
          label: 'Estado',
          field: 'estado',
          sort: true,

        },

      ],
      rows:[],
    },
    dataProps_cadep: {
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'Cisterna',
          field: 'cisterna',
          sort: true
        },
        {
          label: 'Jardin',
          field: 'jardin',
          sort: true
        },
        {
          label: 'Habitaciones',
          field: 'habitaciones',
          sort: true
        },
        {
          label: 'Baños',
          field: 'baño',
          sort: true,
        },
        {
          label: '1/2 Baños',
          field: 'medio_baños',
          sort: true
        },
        {
          label: 'Aire Acondicionado',
          field: 'aire_acondicionado',
          sort: true,

        },
        {
          label: 'Calentador Agua',
          field: 'calentador_agua',
          sort: true,

        },
        {
          label: 'Calefaccion',
          field: 'calefaccion',
          sort: true,

        },
        {
          label: 'Seguridad Priv.',
          field: 'seguridad_privada',
          sort: true,

        },
        {
          label: 'Garaje',
          field: 'garaje',
          sort: true,

        },
        {
          label: 'Estacionamiento',
          field: 'estacionamiento',
          sort: true,

        },
        {
          label: 'Fraccionamiento',
          field: 'fraccionamiento',
          sort: true,

        },
        {
          label: 'Condominio',
          field: 'condominio',
          sort: true,

        },
        {
          label: 'Privada',
          field: 'privada',
          sort: true,
        },
        {
          label: 'Drenaje',
          field: 'drenaje',
          sort: true,

        },
        {
          label: 'FosaSeptica',
          field: 'fosa_septica',
          sort: true,

        },
        {
          label: 'Casa Sola',
          field: 'casa_sola',
          sort: true,

        },
        {
          label: 'Alberca',
          field: 'alberca',
          sort: true,

        },
        {
          label: 'Jacuzzi',
          field: 'jacuzzi',
          sort: true,

        },
        {
          label: 'Alberca Comun',
          field: 'alberca_comun',
          sort: true,

        },
        {
          label: 'NivelesCon',
          field: 'niveles_construidos',
          sort: true,

        },
        {
          label: 'CocinaInt',
          field: 'cocina_integral',
          sort: true,

        },
        {
          label: 'Mascotas',
          field: 'mascotas',
          sort: true,

        },
        {
          label: 'Amueblado',
          field: 'amueblado',
          sort: true,

        },
        {
          label: 'AreaJue',
          field: 'area_juegos',
          sort: true,

        },
        {
          label: 'antiguedad',
          field: 'antiguedad',
          sort: true,

        },



      ],
      rows:[],
    },
    dataProps_bod: {
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'M. Terreno',
          field: 'm_terreno',
          sort: true
        },
        {
          label: 'M. construccion',
          field: 'm_construccion',
          sort: true
        },
        {
          label: 'Estacionamiento',
          field: 'estacionamiento',
          sort: true
        },
        {
          label: 'Techada',
          field: 'techada',
          sort: true,
        },
      ],
      rows:[],
    },
    dataProps_ter: {
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'M. Terren',
          field: 'm_terreno',
          sort: true
        },
        {
          label: 'Bardeado',
          field: 'bardeado',
          sort: true
        },
        {
          label: 'Rustico',
          field: 'rustico',
          sort: true
        },
        {
          label: 'Fraccionamiento',
          field: 'fraccionamiento',
          sort: true,
        },
        {
          label: 'Privada',
          field: 'privado',
          sort: true,
        },
        {
          label: 'CasaSol',
          field: 'solo',
          sort: true,
        },
      ],
      rows:[],
    },
    dataProps_ofi: {
      columns:[
        {
          label: 'Id',
          field: 'id',
          sort: true
        },
        {
          label: 'PlazaComercial',
          field: 'plaza_comercial',
          sort: true
        },
        {
          label: 'SobreCalle',
          field: 'sobre_calle',
          sort: true
        },
        {
          label: 'Coorporativo',
          field: 'coorporativo',
          sort: true
        },
        {
          label: 'Baños',
          field: 'baños',
          sort: true,
        },
        {
          label: 'Estacionamiento',
          field: 'estacionamiento',
          sort: true,
        },
      ],
      rows:[],
    },



  },

  mutations: {

    setValueBooleanos(state,payload){
      if (payload.var == "ShowEditProp"){
        state.ShowEditProp = payload.val
      }else if (payload.var == "ShowAddProp"){
        state.ShowAddProp = payload.val
      }else if (payload.var == "statusTab"){
        state.statusTab = payload.val
      }else if (payload.var == "IndexEditProp"){
        state.IndexEditProp = payload.val
      }else if (payload.var == "Ejemplo"){
        state.Ejemplo = payload.val
      }else if (payload.var == "ParamSearch.TxtPropSearch"){
        state.ParamSearch.TxtPropSearch = payload.val
      }else if (payload.var == "ParamSearch.PropLocalidad"){
        state.ParamSearch.PropLocalidad = payload.val
      }else if (payload.var == "ParamSearch.PropPropiedad"){
        // console.log("denissssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss")
        state.ParamSearch.PropPropiedad = payload.val
      }else if (payload.var == "ParamSearch.PropVentaRenta"){
        state.ParamSearch.PropVentaRenta = payload.val
      }else if (payload.var == "ParamSearch.PropPrecioMin"){
        state.ParamSearch.PropPrecioMin = payload.val
      }else if (payload.var == "ParamSearch.PropPrecioMax"){
        state.ParamSearch.PropPrecioMax = payload.val
      }else if (payload.var == "ParamSearch.PropItemBath"){
        state.ParamSearch.PropItemBath = payload.val
      }else if (payload.var == "ParamSearch.PropItemBed"){
        state.ParamSearch.PropItemBed = payload.val
      }else if (payload.var == "ParamSearch.PropItemCar"){
        state.ParamSearch.PropItemCar = payload.val
      }else if (payload.var == "ParamSearch.PropItemTerreno"){
        state.ParamSearch.PropItemTerreno = payload.val
      }else if(payload.var == "ActSearch"){
        state.ActSearch = payload.val
      }else if(payload.var == "Longitud"){
        state.Longitud = payload.val
      }else if(payload.var == "dataProps_frontend_actual"){
        state.dataProps_frontend_actual = payload.val
      }else if(payload.var == "dataProps_last_actual"){
        state.dataProps_last_actual = payload.val
      }else if(payload.var == "dataSearch"){
        state.dataSearch=payload.val
      }else if(payload.var == "dataProps_frontend_search"){
        state.dataProps_frontend_search=payload.val
      }else if(payload.var == "dataProps_last_search"){
        state.dataProps_last_search=payload.val
      }else if(payload.var == "ActContact"){
        state.ActContact=payload.val
      }else if (payload.var == "LogoUrl") {
        state.LogoUrl = payload.val
      }else if (payload.var == "Message_last") {
        state.Message_last = payload.val
      }else if (payload.var == "dataMessages") {
        state.dataMessages = payload.val
      }else if (payload.var == "ActBtnNext_Message") {
        state.ActBtnNext_Message = payload.val
      }else if (payload.var == "Localidades") {
        state.Localidades = payload.val
      }else if (payload.var == "LogoUrl") {
        state.Localidades = payload.val
      }else if (payload.var == "CounterVisit") {
        state.CounterVisit = payload.val
      }






    },
    setUsuario(state, payload){
      state.usuario = payload
    },
    setError(state,payload){
      state.error =payload
    },
    setImgCarrucel(state,payload){
      state.imgCarrucel = payload
    },
    getDataProps(state,payload){
      state.dataProps = payload
    },
    setTabDataProps(state,payload){
      console.log(payload[0].length)
      state.dataProps_Gen.rows = payload[0]
      state.dataProps_dir.rows = payload[1]
      state.dataProps_cadep.rows = payload[2]
      state.dataProps_bod.rows = payload[3]
      state.dataProps_ter.rows = payload[4]
      state.dataProps_ofi.rows = payload[5]
      state.statusTab=true
    },
    setValueContacto(state, payload){
      state.ContactData = payload
    },
    setValueNosotros(state, payload){
      // console.log("Hello from setValue VUex")
      // console.log(payload)
      state.NosotrosData = payload
      // console.log(state.NosotrosData)
    },
  },
  actions: {
    ReadVisit({commit,state}){
      db.collection("Visits")
      .get()
      .then(function(querySnapshot) {
        commit('setValueBooleanos',{var:'CounterVisit',val:querySnapshot.size})
        console.log(querySnapshot.size)
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });

    },
    //AddCounter
    AddVisit({commit,state}){
        db.collection("Visits").add({
            Id:"visita"
        })
        .then(function() {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    },
    //Read LogoUrl
      readLogo({commit,state}){
          db.collection("General")
            .doc("Logo")
            .get()
            .then(function(doc) {
                  if (doc.exists) {
                      // console.log("denisisisisiisisisisii")
                      // console.log("Document data:", doc.data().src);
                      commit('setValueBooleanos',{var:'LogoUrl',val:doc.data().src})
                  } else {
                      // doc.data() will be undefined in this case
                      console.log("No such document!");
                  }
              }).catch(function(error) {
                  console.log("Error getting document:", error);
              });
      },
    //Read Localidades
      readLocalidades({commit,state}){
          db.collection("Localidades")
            .get()
            .then(function(querySnapshot) {
            var dataLocalidades = []
            querySnapshot.forEach(function(doc) {
              dataLocalidades.push(doc.data().localidad)
            });

              commit('setValueBooleanos',{var:'Localidades',val:dataLocalidades})

          })
      },

    //Read Messages in MyPanel Messages
    readDataMessage({commit,state}){
      console.log("from Vuex in readDataMessage")
      db.collection("Messages")
        .orderBy("time","desc")
        .limit(15)
        .get()
        .then(function(querySnapshot) {
          var last = querySnapshot.docs[querySnapshot.docs.length -1];
          commit('setValueBooleanos',{var:'Message_last',val:last})
          // commit('setValueBooleano',{var:'Message_last',val:last})
          // state.dataProps_frontend_search
          var dataMessages = []
          querySnapshot.forEach(function(doc) {
            dataMessages.push(doc.data())
            //console.log(doc.id, " => ", doc.data());
          });

        commit('setValueBooleanos',{var:'dataMessages',val:dataMessages})
        commit('setValueBooleanos',{var:'ActBtnNext_Message',val:false})
        }).then(()=>{
          var search1 = db.collection("Messages")
          .orderBy("time","desc")
          .limit(15)
          .startAfter(state.Message_last)
          .get()
          .then(function(querySnapshot) {
                if(querySnapshot.empty){
                commit('setValueBooleanos',{var:'ActBtnNext_Message',val:true})
              }


              });

        })
    },
    readDataSearch({commit,state}){
      var search = db.collection("Propiedades");
      if (state.dataSearch.loc != ""){
        search = search.where("DataProp.ciudad","==",state.dataSearch.loc)
      }
      if (state.dataSearch.type != ""){
        search =search.where("DataProp.type","==",state.dataSearch.type)
      }
      if (state.dataSearch.status != ""){
        if(state.dataSearch.status == "renta"){
          search = search.where("DataProp.renta","==",true)
        }else{
          search = search.where("DataProp.venta","==",true)
        }
      }
      if(state.dataSearch.min > 0){
        search = search.where("DataProp.precio",">",state.dataSearch.min)
      }
      if(state.dataSearch.max > 0){
        search = search.where("DataProp.precio","<",state.dataSearch.max)
      }
      if(state.dataSearch.bath > 0){
        search = search.where("DataProp.Baño","==",state.dataSearch.bath)
      }
      if(state.dataSearch.room > 0){
        search =search.where("DataProp.Habitacion","==",state.dataSearch.room)
      }
      if(state.dataSearch.car > 0){
        search = search.where("DataProp.Garaje","==",state.dataSearch.car)
      }
      if(state.dataSearch.terr > 0){
        search = search.where("DataProp.Terreno","==",state.dataSearch.terr)
      }
      search.
      limit(3).
      get()
      .then(function(querySnapshot) {

        var last = querySnapshot.docs[querySnapshot.docs.length -1];
          commit('setValueBooleanos',{var:'dataProps_last_search',val:last})
        // self.ChangeValueBoolean({var:'dataProps_last_actual',val:last})
        // state.dataProps_frontend_search
      var dataPropsFrontend = []
        querySnapshot.forEach(function(doc) {
          dataPropsFrontend.push(doc.data())
          // console.log(doc.id, " => ", doc.data());
        });

      commit('setValueBooleanos',{var:'dataProps_frontend_search',val:dataPropsFrontend})

      });
    },
    getNosotrosData({commit}){
      console.log("Hello form getNosotrosData Vuex")
      db.collection("General").doc("Nosotros").get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                  commit('setValueNosotros', doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });


    },
    getContactData({commit}){
      console.log("Hello form getContactData Vuex")
      db.collection("General").doc("Contacto").get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                  commit('setValueContacto', doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });


    },

    ChangeValueBoolean({commit,state},data){
      console.log("Cambiando valor booleano desde vuex")
      // console.log(data)
      commit('setValueBooleanos',data)
    },
    // async EditPropFirebase({commit},i){
    //   console.log('Editando Propiedad desde Vuex')
    // }
    async DeletePropFirebase({commit,state},i){
      console.log('Elimindado Propiedad desde Vuex')
      // var self = this
      // this.ActLoading=true
      // this.label_loading="Eliminando propiedad ...."
      for(let step=0; step < i.src.length; step++){
        var desertRef = storage.ref().child("ImgPropiedades").child(i.src[step].name)
        desertRef.delete().then(function() {
            console.log("Propiedad eliminada")
          }).catch(function(error) {
            console.log("AN error to try delete")
          });
      }
      db.collection("Propiedades").doc(i.id_general).delete().then(function() {
            console.log("Document successfully deleted!");
            commit('setValueBooleanos',{var:'Ejemplo',val:state.dataProps})
            commit('setValueBooleanos',{var:'ActSearch',val:false})

            // actions.search()

        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });

    },

    tabDataProps({commit,state}){

      commit('setValueBooleanos',{var:'statusTab',val:false})
      // console.log(state.dataProps.D)

      if(state.dataProps.length > 0){
        var data_gen=[]
        var data_row_gen=[]
        var data_row_dir=[]
        var data_row_cadep=[]
        var data_row_bod=[]
        var data_row_ter=[]
        var data_row_ofi=[]

        for (var prop = 0; prop < state.dataProps.length; prop ++){
          var data_row_clone={
            id:state.dataProps[prop].id,
            asesor:state.dataProps[prop].asesor,
            fecha:state.dataProps[prop].fecha,
            nombre:state.dataProps[prop].nombre,
            precio:state.dataProps[prop].precio,
            mantenimiento:state.dataProps[prop].mantenimiento,
          }
          data_row_gen.push(data_row_clone);

          var data_row_clone={
            id:state.dataProps[prop].id,
            calle:state.dataProps[prop].calle,
            numero_ext:state.dataProps[prop].numeroExt,
            numero_int:state.dataProps[prop].numeroInt,
            colonia:state.dataProps[prop].colonia,
            cp:state.dataProps[prop].cp,
            ciudad:state.dataProps[prop].ciudad,
            estado:state.dataProps[prop].Estado,
          }
          data_row_dir.push(data_row_clone);

          var data_row_clone={
            id:state.dataProps[prop].id,
            cisterna:state.dataProps[prop].Cisterna,
            jardin:state.dataProps[prop].Jardin,
            habitaciones:state.dataProps[prop].Habitacion,
            baños:state.dataProps[prop].Baño,
            medio_baño:state.dataProps[prop].MedioBaño,
            aire_acondicionado:state.dataProps[prop].AireAco,
            calentador_agua:state.dataProps[prop].CalentadorAgu,
            calefaccion:state.dataProps[prop].Caleffacion,
            seguridad_privada:state.dataProps[prop].SeguridadPriv,
            garaje:state.dataProps[prop].Garaje,
            estacionamiento:state.dataProps[prop].LugarEst,
            fraccionamiento:state.dataProps[prop].Fraccionamiento,
            condominio:state.dataProps[prop].Condominio,
            privada:state.dataProps[prop].Privada,
            drenaje:state.dataProps[prop].Drenaje,
            fosa_septica:state.dataProps[prop].FosaSep,
            casa_sola:state.dataProps[prop].CasaSol,
            alberca:state.dataProps[prop].Alberca,
            jacuzzi:state.dataProps[prop].Jacuzzi,
            alberca_comun:state.dataProps[prop].AlbercaCom,
            niveles_construidos:state.dataProps[prop].NivelesCon,
            cocina_integral:state.dataProps[prop].CocinaInt,
            mascotas:state.dataProps[prop].Mascotas,
            amueblado:state.dataProps[prop].Amueblado,
            area_juegos:state.dataProps[prop].AreaJue,
            antiguedad:state.dataProps[prop].Antiguedad,

          }
          data_row_cadep.push(data_row_clone);

          var data_row_clone={
            id:state.dataProps[prop].id,
            m_terreno:state.dataProps[prop].Terreno,
            m_construccion:state.dataProps[prop].Construccion,
            estacionamiento:state.dataProps[prop].Estacionamiento,
            techada:state.dataProps[prop].Techada,

          }
          data_row_bod.push(data_row_clone);

          var data_row_clone={
            id:state.dataProps[prop].id,
            m_terreno:state.dataProps[prop].Terreno,
            bardeado:state.dataProps[prop].Bardeado,
            rustico:state.dataProps[prop].Estacionamiento,
            fraccionamiento:state.dataProps[prop].Fraccionamiento,
            privado:state.dataProps[prop].Privada,
            solo:state.dataProps[prop].CasaSol,
          }

          data_row_ter.push(data_row_clone);

          var data_row_clone={
            id:state.dataProps[prop].id,
            plaza_comercial:state.dataProps[prop].PlazaComercial,
            sobre_calle:state.dataProps[prop].SobreCalle,
            coorporativo:state.dataProps[prop].Coorporativo,
            baños:state.dataProps[prop].Baño,
            estacionamiento:state.dataProps[prop].LugarEst,
          }

          data_row_ofi.push(data_row_clone);


        }

        data_gen.push(data_row_gen);
        data_gen.push(data_row_dir);
        data_gen.push(data_row_cadep);
        data_gen.push(data_row_ter);
        data_gen.push(data_row_bod);
        data_gen.push(data_row_ofi);

        commit('setTabDataProps', data_gen)

      }
    },

    // funcion para obtener la imagen de Logo
    getLogo({commit,state}){
      console.log("estoy en store from getLogo")
      db.collection("General").doc("Logo").onSnapshot(docSnapshot => {
            commit('setValueBooleanos',{var:"LogoUrl",val:docSnapshot.data().src})
            console.log(state.LogoUrl);
          }, err => {
            console.log(`Encountered error: ${err}`);
          });

    },

    // funcion para obtener los datos de las propiedades
    getProps({commit,state}){
      console.log("estoy en store")
      console.log(state.statusDowProp)
      if (state.statusDowProp == false){

        db.collection("Propiedades")
        .onSnapshot(function(querySnapshot) {
          var data_props = [];
          querySnapshot.forEach(function(doc) {
            data_props.push(doc.data().DataProp)
          });
          commit('getDataProps', data_props)
          var paginations=[]
          for(var i = 0; i <= parseInt((state.dataProps.length)/5,10); i++){
            paginations.push(i)
          }
          console.log("denisa holaaaaaaaaaaaaaaaaaa")
          console.log(state.dataProps.length)
          console.log(parseInt((state.dataProps.length)/5,10))
          commit('setValueBooleanos',{var:'Longitud',val:paginations})
        });
      }
    },

    // funcion para obtener los datos del carrucel
    getImgCarrucel({commit,state}){
      db.collection("Carrucel")
      .onSnapshot(function(querySnapshot) {
        var img_carrucel = [];
        querySnapshot.forEach(function(doc) {
          var doc_copy={
              img: doc.data().ImgName,
              src: doc.data().UrlDownload,
              id: doc.id
          }
          img_carrucel.push(doc_copy);
        });
        // console.log(img_carrucel)

        commit('setImgCarrucel', img_carrucel)
      });

    },

    // funciones  para  el login principal
    login_user({commit},usuario){
      auth.signInWithEmailAndPassword(usuario.email, usuario.password)
      .then(res=> {
        console.log(res)
        const usuario ={
          email: res.user.email,
          uid: res.user.uid
        }

        commit('setUsuario', usuario)
        router.push('/mypanel')
      })
      .catch(error => {
        alert('Usuario o contraseña incorrecto')
        console.log(error)
        commit('setError',error)
      })
    },

    out_session({commit}){
      auth.signOut()
      .then(()=>{
        router.push('/login')
      })
    },

    detect_user({commit},usuario){
      commit('setUsuario',usuario)
    },


    // funciones  para  el SearchProps
    search({commit,state}){
        commit('setValueBooleanos',{var:'Ejemplo',val:state.dataProps.slice()})
        console.log(state.ParamSearch.TxtPropSearch)
        if(state.ParamSearch.TxtPropSearch != "") {
          var  DataTxtPropSearch=[]
            for (let prop of state.dataProps){
                  let id = prop.id.toLowerCase()
                  if(id.indexOf(state.ParamSearch.TxtPropSearch) !== -1){
                      DataTxtPropSearch.push(prop)
                    }
               }
               commit('setValueBooleanos',{var:'Ejemplo',val:DataTxtPropSearch.slice()})
         }

         if(state.ParamSearch.PropLocalidad != null){
             console.log("Busqueda por localidad activada")
             var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
               return ArrayFilter.ciudad === state.ParamSearch.PropLocalidad});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
           }
         console.log(state.ParamSearch.PropPropiedad)
         if(state.ParamSearch.PropPropiedad != null){
              console.log("Busqueda por propiedad activada")

               var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
                 return ArrayFilter.type === state.ParamSearch.PropPropiedad});
                 commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
           }

         if(state.ParamSearch.PropVentaRenta != null){
            console.log("Busqueda por venta y renta ")
               if(state.ParamSearch.PropVentaRenta == 'venta'){
                 var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
                   return ArrayFilter.venta === true});
                  commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
               }else if(state.ParamSearch.PropVentaRenta == 'renta'){
                 var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
                   return ArrayFilter.renta === true});
                   commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
               }
             }

         if(state.ParamSearch.PropPrecioMin != null && state.ParamSearch.PropPrecioMin > 0){
            console.log("Busqueda precio minimo ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.precio > state.ParamSearch.PropPrecioMin});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }


         if(state.ParamSearch.PropPrecioMax != null && state.ParamSearch.PropPrecioMax > 0){
            console.log("Busqueda precio minimo ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.precio < state.ParamSearch.PropPrecioMax});
              this.Ejemplo =  ArrayFilter.slice()
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }


         if(state.ParamSearch.PropItemBath > 0){
            console.log("Busqueda baños disponibles ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.Baño == state.ParamSearch.PropItemBath});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }

         if(state.ParamSearch.PropItemBed > 0){
            console.log("Busqueda Habitaciones disponibles ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.Habitacion == state.ParamSearch.PropItemBed});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }

         if(state.ParamSearch.PropItemCar > 0){
            console.log("Busqueda estacionamientos disponibles ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.Estacionamiento == state.ParamSearch.PropItemCar});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }

         if(state.ParamSearch.PropItemTerreno > 0){
            console.log("Busqueda por metro de terreno disponibles ")
            var ArrayFilter = state.Ejemplo.filter(function(ArrayFilter) {
              return ArrayFilter.Terreno == state.ParamSearch.PropItemTerreno});
              commit('setValueBooleanos',{var:'Ejemplo',val:ArrayFilter.slice()})
         }
    }
  },
  modules: {
  }
})
