import { firebase } from '@firebase/app';
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

var firebaseConfig = {
    apiKey: "AIzaSyBa3WxzCQrB9V2Et36F96psFzl0U-Q8yTU",
    authDomain: "novoinmobiliaria-eaea8.firebaseapp.com",
    databaseURL: "https://novoinmobiliaria-eaea8.firebaseio.com",
    projectId: "novoinmobiliaria-eaea8",
    storageBucket: "novoinmobiliaria-eaea8.appspot.com",
    messagingSenderId: "115317775813",
    appId: "1:115317775813:web:8c80ec4229e41cf62e7fac",
    measurementId: "G-V128GN48VQ"
  };

// var firebaseConfig = {
//     apiKey: "AIzaSyAh2sZc1hfWegma9DXVbYg_KBIlOP0Xmvs",
//     authDomain: "ejemplo-aa1fd.firebaseapp.com",
//     projectId: "ejemplo-aa1fd",
//     storageBucket: "ejemplo-aa1fd.appspot.com",
//     messagingSenderId: "1029601798465",
//     appId: "1:1029601798465:web:da7ccd2c6c20d39cbe99c0",
//     measurementId: "G-PN2HFPBVRT"
//   };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
//
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export {firebase, db, auth, storage}
