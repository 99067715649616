<template>
  <!-- ******************* Start Sobre Nosotros ************************** -->
  <div id="aboutus">
    <mdb-container>
      <!-- <mdb-card class="text-left" reverse> -->
        <mdb-card-title class="Texto3">Sobre Nosotros</mdb-card-title>
        <mdb-card-body class="text-left">
          <mdb-container>
            <mdb-row>
              <mdb-col col="12"  sm="12" md="12" lg="6" xl="6">
                <mdb-card class="text-left" reverse>
                  <mdb-card-image :src="NosotrosData.src" alt="Card image cap"></mdb-card-image>
                </mdb-card>
              </mdb-col>
              <mdb-col col="12"  sm="12" md="12" lg="6" xl="6">
                <mdb-card class="text-justify" reverse>
                  <mdb-card-body class="text-justify" >
                    <mdb-container  class="text-justify">
                      {{NosotrosData.text}}
                    </mdb-container>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-card-body>
      <!-- </mdb-card> -->

    </mdb-container>
    <br>
    <mdb-row>
      <mdb-col style="background-color: #00796b"   sm="2">
        <br>
      </mdb-col>
    </mdb-row>
    <br>
  </div>
  <!-- ******************* End Sobre Nosotros ************************** -->
</template>


<script>
import { mapActions,mapState } from 'vuex'
  import { mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup} from 'mdbvue';
  export default {
    name: 'aboutus',
    components: {
      mdbContainer,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
    },
    data() {
      return {
      };
    },
    methods: {
      ...mapActions(['getNosotrosData'])
    },
    computed:{
      ...mapState(['NosotrosData'])
    },
    created () {
      console.log('2 - created frome AboutUs.vue')
      this.getNosotrosData()
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  h1 {
    font-family: "sans-serif";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .Texto1{
    font-family: "Georgia";
    /* font-weight: bold;
    text-transform: uppercase; */
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .Texto4{
    font-family: "Segoe UI";
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 25px;
  }


</style>
