<template>
  <!-- https://api.whatsapp.com/send?phone=527775655498&text=Hola!&nbsp; -->
  <div id="whatsapp">
    <!-- v-bind:href="ContactData.whatsapp" -->
    <a class="appWhatsapp" v-on:click="SendWhatsapp()" >
      <img src="../assets/whatsapp.png"></img>
    </a>
  </div>
</template>


<script>
import { mapActions,mapState } from 'vuex'
export default {
  name: 'actualproperties',
  props:['id_general'],
  data() {
    return {
    };
  },
  computed:{
      ...mapState(['ContactData'])
    },
  methods: {
    ...mapActions(['getContactData']),
    SendWhatsapp(){
      var self = this
      // self.ContactData.tel
      if (self.id_general != null){
        var   dir="https://api.whatsapp.com/send?phone=+" + self.ContactData.tel  +  "&text=Hola Me gustaria informacion de la propiedad: " + "http://www.novoinmobiliaria.com.mx/propiedad/" + self.id_general + " &nbsp;"
      }else{
        var   dir="https://api.whatsapp.com/send?phone=+" +  self.ContactData.tel  + "&text=Hola!&nbsp;"
      }

      location.href=dir
      // self.$router.push('home')
    },
  },
  // beforeCreate () {
  //   console.log('1 - beforeCreate from FooterInfo.vue')
  // },
  created () {
    console.log('2 - created frome Whatsapp.vue')
    this.getContactData()
    console.log("diego")
    console.log(this.ContactData)
  },

  // beforeMount () {
  //   console.log('3 - beforeMount frome FooterInfo.vue')
  // },
  created () {
    console.log('2 - created frome FooterInfo.vue')
    this.getContactData()
  },
  // beforeUpdate () {
  //   console.log('5 - beforeUpdate frome Home.vue')
  // },
  // Updated () {
  //   console.log('6 - Update')
  // },

  }
</script>



<style scoped>
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.appWhatsapp{
  position:fixed;
  right: 26px;
  bottom:100px;
  width: 60px;
  z-index:100;
}
.appWhatsapp img{
  width:100%;
  height:auto;
}

</style>
