<template>
  <!-- ******************* Start  Recientes ************************** -->
    <div id="findproperties">
      <mdb-container>
        <mdb-card>

          <mdb-card-body>
            <mdb-card-title style="font-weight: bold;" class="Texto2  text-left"  >Encuentra tu hogar</mdb-card-title>
            <mdb-container>
              <h2 class="Texto1 text-left">Localidad</h2>
              <select v-model="dataSearch.loc" class="browser-default custom-select">
                <option value="" selected> Selected </option>
                <option  v-for="item in Localidades" :value="item">{{item}}</option>
                <!-- <option value="Jiutepec">Jiutepec</option> -->
              </select>
              <h2 class="Texto1 text-left">  Tipo de propiedad</h2>

              <select v-model="dataSearch.type" class="browser-default custom-select">
                <option value="" selected> Selected</option>
                <option value="Casa">Casa</option>
                <option value="Departamento">Departamento</option>
                <option value="Bodega">Bodega</option>
                <option value="Terreno">Terreno</option>
                <option value="Oficina">Oficina</option>
                <option value="LocalComercial">Local Comercial</option>
              </select>
              <h2 class="Texto1 text-left"> Estatus de la propiedad </h2>
              <select  v-model="dataSearch.status" class="browser-default custom-select">
                <option value="" selected> Selected </option>
                <option value="renta">Renta</option>
                <option value="venta">Venta</option>
              </select>
              <h2 class="Texto1 text-left"> Precio (Min-Max)</h2>
              <div class="input-group">
                <mdb-container>
                  <mdb-row>
                    <mdb-col col="5">
                      <input v-model="dataSearch.min" type="number" value="0" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                    </mdb-col>
                    <mdb-col col="1">
                      <p class="text-left">-</p>
                    </mdb-col>
                    <mdb-col col="5">
                      <input v-model="dataSearch.max" type="number" value="0" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                    </mdb-col>
                  </mdb-row>
                </mdb-container>
              </div>

              <h2 class="Texto1 text-left"> Descripcion</h2>
              <div class="input-group">
                <mdb-row>
                  <mdb-col sm="4">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="bath" />
                      </span>
                      <input v-model="dataSearch.bath" type="number"   class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                    </div>
                  </mdb-col>
                  <br>
                  <mdb-col sm="4">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="bed" />
                      </span>
                      <input v-model="dataSearch.room" type="number" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                    </div>
                  </mdb-col>
                  <br>
                  <mdb-col sm="4">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <mdb-icon icon="car" />
                      </span>
                      <input v-model="dataSearch.car" type="number" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                    </div>
                      <br>
                </mdb-col>
                <mdb-col sm="4">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon">
                      <img src="@/assets/terreno.png" width="22" height="22">
                    </span>
                    <input v-model="dataSearch.terr" type="number" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon">
                  </div>
                    <br>
              </mdb-col>


              </mdb-row>
              </div>
            </mdb-container>
          </mdb-card-body>
          <div>
            <mdb-btn @click="ReadProps"  size="lg" style="background-color: #00796b " > Buscar </mdb-btn>
          </div>
        </mdb-card>
    	</mdb-container>
    	</mdb-container>
      <br>
      <!-- {{ dataProps_frontend_search }} -->
      <!-- <mdb-row>
        <mdb-col style="background-color: #00796b"   sm="2">
          <br>
        </mdb-col>
      </mdb-row>
      <br> -->
    </div>
  <!-- ******************* END Recientes ************************** -->
</template>





<script>
import router from '../router'
import { mapActions,mapState } from 'vuex'
import { firebase, storage, db } from "@/firebase";
import { mdbFooter} from 'mdbvue';
import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem, mdbContainer, mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav, mdbNavItem } from 'mdbvue';
import {mdbInput} from "mdbvue";
export default {
    name: 'findproperties',
    components: {
      mdbInput,
      mdbFooter,
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
      mdbContainer,
      mdbDropdown,
      mdbDropdownToggle,
      mdbDropdownMenu,
      mdbDropdownItem,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
			mdbBtn,
			mdbView,
			mdbMask,
			mdbIcon,

    },
    data() {
      return {

      };
    },
    methods: {
      ...mapActions(['ChangeValueBoolean','readDataSearch']),
      ReadProps(){
        var self =this
        var data={
          loc:this.dataSearch.loc,
          type:this.dataSearch.type,
          status:this.dataSearch.status,
          min:this.dataSearch.min,
          max:this.dataSearch.max,
          bath:this.dataSearch.bath,
          room:this.dataSearch.room,
          car:this.dataSearch.car,
          terr:this.dataSearch.terr,
        }
        self.ChangeValueBoolean({var:'dataSearch',val:data})
        self.readDataSearch()
        if(self.$route.name == 'Home'){
          self.$router.push({ path: '/properties' })
        }
    },


  },
  computed:{
    ...mapState(['dataSearch','dataProps_frontend_search','Localidades'])
  },
}

</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  .Texto1{
    font-family: "Georgia";
    /* font-weight: bold;
    text-transform: uppercase; */
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .container {
    text-align: center;
  }
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}

</style>
