<template>
  <!-- ******************* Start Navbar ************************** -->
    <div id="navbargeneral">
      <mdb-navbar  expand="small" color="white" light>
        <mdb-navbar-brand>
          <img :src="LogoUrl" height="150" alt="">
        </mdb-navbar-brand>
        <mdb-navbar-toggler>
          <mdb-navbar-nav right>

	  <mdb-nav-item  active><h1 style="color: black"><router-link to="/home">  Inicio </router-link></h1></mdb-nav-item>
            <mdb-nav-item  active><h1 style="color: black"><router-link to="/properties">  Propiedades </router-link></h1></mdb-nav-item>
            <mdb-nav-item  active><h1 style="color: black"><router-link to="/about">  Nosotros </router-link></h1></mdb-nav-item>
            <mdb-nav-item  active><h1 style="color: black"><router-link to="/contact">  Contactanos </router-link></h1></mdb-nav-item>

            <!-- <mdb-nav-item href="/home" active><h1 style="color: black">Inicio</h1></mdb-nav-item>
            <mdb-nav-item href="/properties" active><h1 style="color: black">Propiedades</h1></mdb-nav-item>
            <mdb-nav-item href="/about" active><h1 style="color: black">Nosotros</h1></mdb-nav-item>
            <mdb-nav-item href="/contact" active><h1 style="color: black">Contactanos</h1></mdb-nav-item> -->

          </mdb-navbar-nav>
        </mdb-navbar-toggler>
      </mdb-navbar>
      <div style="background-color: #00796b" class="clearfix">
        <br>
      </div>
      <br>
    </div>
  <!-- ******************* End Navbar ************************** -->
</template>


<script>
  import { mapActions,mapState } from 'vuex'
  import { mdbFooter} from 'mdbvue';
  import { mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
  import { mdbJumbotron} from 'mdbvue';
  import { mdbCarousel } from "mdbvue";
  import { mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem, mdbContainer, mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav, mdbNavItem } from 'mdbvue';
  import {mdbInput} from "mdbvue";
  export default {
    name: 'navbargeneral',
    components: {
      mdbInput,
      mdbFooter,
      mdbCarousel,
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
      mdbContainer,
      mdbDropdown,
      mdbDropdownToggle,
      mdbDropdownMenu,
      mdbDropdownItem,
      mdbRow,
			mdbCol,
			mdbCard,
			mdbCardImage,
			mdbCardHeader,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbCardFooter,
			mdbCardUp,
			mdbCardAvatar,
			mdbCardGroup,
			mdbBtn,
			mdbView,
			mdbMask,
			mdbIcon,
      mdbJumbotron,
    },
    data() {
      return {
        fields: {
          name: "",
          email: "",
          subject: "",
          message: ""
        },
        ItemsRecientes:["1","2","1","2","1","2"],
        items: [
          {
            img: true,
            src:
              "https://mdbootstrap.com/img/Photos/Slides/img%20(45).jpg"
          },
          // {
          //   img: true,
          //   src:
          //     "https://mdbootstrap.com/img/Photos/Slides/img%20(46).jpg"
          // },
          {
            img: true,
            src:
              "https://mdbootstrap.com/img/Photos/Slides/img%20(47).jpg"
          }
        ]
      };
    },
    methods: {
      submitForm(event) {
        event.target.classList.add("was-validated");
        // submit form
      }
    },
    computed:{
      ...mapState(['LogoUrl'])
    }
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
  h1 {
    font-family: "sans-serif";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .Texto1{
    font-family: "Georgia";
    /* font-weight: bold;
    text-transform: uppercase; */
    font-size: 21px;
  }
  .Texto2{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
  }
  .Texto3{
    font-family: "Segoe UI";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  .Texto4{
    font-family: "Segoe UI";
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 25px;
  }
  .container {
    text-align: center;
  }
  .img1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.appWhatsapp{
  position:fixed;
  right: 26px;
  bottom:100px;
  width: 60px;
  z-index:100;
}
.appWhatsapp img{
  width:100%;
  height:auto;
}

</style>
